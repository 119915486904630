<template>
  <div class="home page">
    <div class="gradient"></div>
        <header>

            <div class="nav-container">
                <router-link to="/"><img src="@/assets/img/logo.png" alt="logo" class="logo"></router-link>
                <Nav/>
            </div>
            <div>
                <SlideshowAdv />
            </div>
        </header>
        <section class="content">

            <section>
                <div class="section">
                    <div el="list sm">
                        <h3 el>Amenities</h3>
                        
                        <!-- <SlideshowAdv /> -->
                        <div el="list xxsm">
                            <Accordion title="Basketball Court">
                                <div el="list" style="margin-left:32px;">
                                    <h4 el>Basketball Court Rules</h4>
                                    <p el>
                                        These rules exist for the purpose of maintaining our courts and providing an opportunity for all to use them. Each resident is asked to cooperate, and to assist in enforcing them. Please be considerate of others so that the courts may be enjoyed by everyone entitled to use them.
                                    </p>
                                    <ol el>
                                        <li>Sneakers or tennis shoes only may be worn on the basketball courts.</li>
                                        <li>Players are expected to wear appropriate attire. <b>SHIRTS MUST BE WORN AT ALL TIMES</b>. Bathing suits are not permitted.</li>
                                        <li>Bicycles, roller skates, skateboards and pets are not permitted on the courts. No ball playing of any kind other than basketball is permitted.</li>
                                        <li>The basketball court is solely intended for residents and their guests only.</li>
                                        <li>There is a 1.5 hour playing time limit, particularly if other homeowners are waiting to play.</li>
                                        <li>No commercial use without prior notification to association.</li>
                                    </ol>
                                </div>
                            </Accordion>
                            <Accordion title="Clubhouse">
                                <div el="list" style="margin-left:32px;">
                                    <p el>
                                        The clubhouse is 3840 square feet of space available for residents to rent 7 days a week. The clubhouse includes a pool house, a small kitchen, a professionally decorated large meeting room and fitness center. The big meeting room is available for rent for residents for $250.00. Please contact First Residential for the forms necessary to rent this facility. Call 703-385-1133
                                    </p>
                                    <p el>
                                        61 chairs, 7 long tables, 5 round tables in closet, 23 seating on sofas/chairs - this includes sit down seating at 3 round wooden tables with 12 chairs. 78 parking spaces including 4 handicap, 55 inch flat screen tv, blue ray DVD player Verizon cable, a 120 inch portable projection screen and wireless internet. The kitchen has a microwave, four burner stove, oven, refrigerator and freezer, dish washer and keurig coffee maker, water fountain, coat closet restrooms and showers. (Max. Big meeting room capacity: 110)
                                    </p>
                                    <p el>
                                        Once your deposit and application has been accepted by First Residential and your event has been added to the calendar, then you may reach out to Hien Aceron by text at 703-587-0360 for further questions regarding the clubhouse.
                                    </p>
                                    <p el="bold">Important Information</p>   
                                    <ul el>
                                        <li>Location: 43919 Edgewater Street Chantilly, VA 20152</li>
                                        <li>Phone: 703-385-1133</li>
                                    </ul>
                                </div>
                            </Accordion>
                            <Accordion title="Fitness Center">
                                <div el="list" style="margin-left:32px;">
                                    <p el>
                                        The Fitness Center is equipped with health club quality fitness equipment, including two tread mills, two ellipticals, one recumbent bike and and one stationary bike. Also, a flat screen TV with Verizon cable is mounted on the wall. One corner of the room has foam tiles for stretching and conditioning exercises. Restrooms and showers are available. A <a href="#waiver" target="_blank" el="link">waiver form</a> must be signed before issuing the Fitness Center entry key fob.
                                    </p>
                                </div>
                            </Accordion>
                            <Accordion title="Pool">
                                <div el="list" style="margin-left:32px;">
                                    <p el>
                                        The pool facility includes a 25 meter pool. In addition, it includes a zero depth walk-in entry infant pool. Pool also has family friendly deck music and tube slide.
                                    </p>
                                    <p el="bold">HOURS OF OPERATION WHEN PUBLIC SCHOOL IS IN SESSION</p>
                                    <ul el>
                                        <li>Mon-Fri: 4pm-8pm</li>
                                        <li>Sat-Sun: 11am-8pm</li>
                                    </ul>
                                    <p el="bold">HOURS OF OPERATION: REGULAR SCHEDULE</p>
                                    <ul el>
                                        <li>Daily: 11am-8pm</li>
                                        <li>Holidays: 11am-8pm</li>
                                    </ul>
                                    <p el="bold">Important Information</p>   
                                    <ul el>
                                        <li>Opens: May 26, 2021</li>
                                        <li>Closes: Sept 3, 2021</li>
                                        <li>Phone: 703-327-8263</li>
                                    </ul>

                                </div>
                            </Accordion>
                            <Accordion title="Tennis Courts">
                                <div el="list" style="margin-left:32px;">
                                    <p el>
                                        Two regulation sized tennis courts sit adjacent to the clubhouse and pool area.
                                    </p>
                                    <h4 el>Tennis Court Rules</h4>
                                    <p el>
                                        These rules exist for the purpose of maintaining our courts and providing an opportunity for all to use them. Each resident is asked to cooperate, and to assist in enforcing them. Please be considerate of others so that the courts may be enjoyed by everyone entitled to use them.
                                    </p>
                                    <ol el>
                                        <li>The Association is represented by the Managing Agent. Any conflicts shall be addressed to the Managing Agent, who will bring the issue to the Board of Directors if it cannot be satisfactorily resolved.</li>
                                        <li>The Managing Agent has the authority to use its discretion to enforce the rules to maintain a safe and healthy environment. The Managing Agent has the authority to ask anyone to leave the tennis courts for infractions of the rules or when safety is threatened.</li>
                                        <li>Safety is of primary concern to the Association and its Members. All persons using the tennis courts do so at their own risk; agrees to hold Alphaleaf Homeowners Association and its Board of Directors harmless of injury and agrees to abide by the rules for use of the facilities. The Association assumes no responsibility for any accident or injury in connection with such use or for any loss or damage to personal property. Residents (Members and tenants) are responsible for the actions of their children and guests.</li>
                                        <li>The tennis courts hours of operation are dawn to dusk. No person shall use the tennis courts unless the tennis courts are officially open. Unauthorized persons found inside the tennis courts enclosure when the tennis courts are closed may lose their privileges for a period not to exceed sixty (60) days and risk prosecution for trespassing or other related offenses.</li>
                                        <li>Use of the tennis courts shall be limited to a one (1) hour time limit for singles play and a two (2) hour time limit for doubles play if there are persons waiting to use the court.</li>
                                        <li>No more than four players per court are permitted on the tennis courts.</li>
                                        <li>The teaching of tennis lessons is prohibited.</li>
                                        <li>Smoking is prohibited on the courts.</li>
                                        <li>Food is prohibited on the courts.</li>
                                        <li>Glass and other breakable objects are prohibited on courts.</li>
                                        <li>Intoxicants are prohibited on the courts. Intoxicated persons are prohibited on the courts at any time.</li>
                                        <li>Courts are to be used for their intended purpose only. Play equipment including skates and skateboards, toys, play pens, wheeled vehicles including bicycles (except wheelchairs), scooters or motorized vehicles are prohibited on the courts.</li>
                                        <li>Pets (except service animals) are prohibited on the tennis courts.</li>
                                        <li>The use of radios, televisions or similar devices is permitted only when used with headphones.</li>
                                        <li>All refuse must be placed in containers provided for this purpose. Residents are urged to assist in keeping the tennis courts clean and pleasant.</li>
                                        <li>Use of the tennis courts must be conducted in a safe manner with due respect and consideration for others who are in the nearby areas. Tennis courts rules and courtesies apply at all times. The use of profane or obscene language is prohibited. Rough play or abusive conduct is prohibited.</li>
                                        <li>Only shoes designed specifically for playing tennis are permitted on the tennis courts. Proper attire should be worn. Black soled shoes are prohibited on the tennis courts.</li>
                                        <li>The Association is not responsible for the loss of or damage to any personal property used or left in the recreational amenities area.</li>
                                        <li>The tennis courts may be closed at the discretion of the Board of Directors or the Managing Agent.</li>
                                    </ol>

                                </div>
                            </Accordion>
                            <Accordion title="Tot-lot">
                                <div el="list" style="margin-left:32px;">
                                    <p el>
                                        Location: Adjacent to the pool and tennis courts.
                                    </p>
                                    
                                </div>
                            </Accordion>
                        </div>





                    </div>
                </div>

            </section>
            <!-- <section>

                <div class="section">
                    <div>
                        <h4 el>Upcoming Events</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque laoreet dolor in venenatis blandit. Curabitur et ex nec dui dapibus efficitur vel eu ipsum. Maecenas dignissim velit mollis, accumsan neque eu, vulputate felis. Integer lobortis dapibus justo, et suscipit arcu. Praesent at suscipit justo. Phasellus aliquet efficitur elit, eget vulputate nisl. Nullam non dolor gravida, suscipit dui at, lacinia neque.</p>
                        <button>Get Involved</button>
                    </div>
                </div>
            </section>
            <section>

                <div class="section-news section">
                    <div>
                        <h4 el>News</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque laoreet dolor in venenatis blandit. Curabitur et ex nec dui dapibus efficitur vel eu ipsum. Maecenas dignissim velit mollis, accumsan neque eu, vulputate felis. Integer lobortis dapibus justo, et suscipit arcu. Praesent at suscipit justo. Phasellus aliquet efficitur elit, eget vulputate nisl. Nullam non dolor gravida, suscipit dui at, lacinia neque.</p>
                        <button>View More</button>
                    </div>
                </div>
            </section> -->

        </section>
        <Footer />
  </div>

</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Accordion from '@/components/Accordion.vue'
import SlideshowAdv from '@/components/SlideshowAdv.vue'

export default {
  name: 'Amenities',
  components: {
      Nav,
      Footer,
      Accordion,
      SlideshowAdv
  },
  data(){
    return {

    }
  }
}
</script>
<style>
/* .slideshow{
    width:100vw;
}
.slideshow .slides{
    height:700px;
} */
header{
    background-color: rgba(0, 0, 0, 0.6);
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
@media (max-width:600px){
    header{
        min-height: 300px;
    }
    .content{
        margin-top: -50px;
    }
    .slideshow .slides {
        height: 300px !important;
    }
}
</style>
